import * as R from "ramda";
import { defaultFor } from "common";
import { hasPermissionToUpdate } from "common/functions/roles";
import { Context } from "common/types/context";
import { IntLabel } from "common/widgets/number/label";
import { Properties as Value } from "common/types/records";
import { getDataTypeForAggregateFn } from "common/entities/data-types";
import { dataTypesWithEllipsis } from "common/entities/entity-column/data-type/types";
import { EntityColumn } from "common/entities/entity-column/types";
import { AdditionalProps } from "common/form/group/functions";
import { WidgetsOverwriteMap } from "common/form/types";
import { LabelWidget } from "common/form/widget/label-widget";
import { SelectItem } from "common/query/types";
import { arrayToString } from "common/utils/array";
import { SystemIntFk } from "common/types/system-int";
import { ImageSmallTable } from "common/widgets/image";
import { getSrcParameters } from "common/widgets/image/functions";
// eslint-disable-next-line import/no-cycle
import { QuickInput } from "common/widgets/quick-input";
import { ValueComponent, ValueProps } from "common/with-value-for";
import { isSelfReferencedNonFkField } from "./functions";

interface PropTypes {
  context: Context;
  entity: string;
  recordId: string;
  relatedRecordId?: string;
  column: EntityColumn;
  columnEntity: string;
  item: SelectItem;
  value: any;
  hasEmphasis: boolean;
  withLinks: boolean;
  readOnly?: boolean;
  additionalProps?: AdditionalProps;
  dependencies?: Value;
  widgetsMap?: WidgetsOverwriteMap;
  isDisabled?: (item: SystemIntFk) => boolean;
}

type Props = PropTypes & ValueProps<Value>;

export class TableCell extends ValueComponent<Value, PropTypes> {
  static readonly displayName = "TableCell";

  shouldComponentUpdate(newProps: Props) {
    const {
      column: oldCol,
      widgetsMap,
      item,
      additionalProps,
      dependencies,
      value,
    } = this.props;
    const forceUpdate = !!widgetsMap?.[oldCol.name]?.forceUpdate;
    const newCol = newProps.column;

    return (
      forceUpdate ||
      (oldCol && !newCol) ||
      (!oldCol && !!newCol) ||
      (oldCol && newCol && oldCol.name !== newCol.name) ||
      value !== newProps.value ||
      !R.equals(item, newProps.item) ||
      !R.equals(additionalProps, newProps.additionalProps) ||
      !R.equals(dependencies, newProps.dependencies)
    );
  }

  getCell = () => {
    const {
      context,
      additionalProps,
      withLinks,
      recordId,
      entity,
      readOnly,
      value,
      dependencies,
      widgetsMap,
      column = defaultFor<EntityColumn>(),
      isDisabled,
      columnEntity,
      relatedRecordId,
      item,
    } = this.props;
    const { dataType, quickInput } = column;
    const showWidgetLink =
      withLinks &&
      !isSelfReferencedNonFkField(item, column, entity, columnEntity);

    if (getDataTypeForAggregateFn(item) === "int") {
      return <IntLabel value={value} />;
    }

    if (dataType === "image") {
      return (
        <ImageSmallTable
          value={value}
          allowZoom={true}
          srcParameters={getSrcParameters(context)}
        />
      );
    }

    if (quickInput && !readOnly) {
      const hasPermissionsToEdit = hasPermissionToUpdate(
        context.userTypes,
        context.role,
        entity,
      );

      if (hasPermissionsToEdit) {
        return (
          <QuickInput
            {...additionalProps}
            context={context}
            column={column}
            entity={context?.entities?.[entity]}
            options={column.quickInputOptions}
            isDisabled={isDisabled}
            dependencies={dependencies}
            widgetsMap={widgetsMap}
            value={value}
            onChange={this.onChangeSetValue}
          />
        );
      }
    }

    return (
      <LabelWidget
        {...additionalProps}
        {...dependencies}
        context={context}
        withLinks={showWidgetLink}
        widgetsMap={widgetsMap}
        column={column}
        isOnAList={true}
        recordId={recordId}
        relatedRecordId={relatedRecordId}
        entityName={columnEntity}
        columnEntity={entity}
        value={value}
      />
    );
  };

  render() {
    const { hasEmphasis, column = defaultFor<EntityColumn>() } = this.props;
    const { dataType, quickInput } = column;

    const hasEllipsis =
      R.includes(dataType, dataTypesWithEllipsis) && !quickInput;

    const className = arrayToString([
      dataType === "image" ? "x-align-center" : undefined,
      hasEllipsis ? "x-ellipsis" : undefined,
      hasEmphasis ? "x-bold" : undefined,
    ]);
    return <div className={className}>{this.getCell()}</div>;
  }
}
