import * as R from "ramda";
import { JSX } from "react";
import { getLocalizedName, toCamelCase } from "common";
import { getColumn } from "common/entities";
import { MapWidgetPropsFn } from "common/form/types";
import { LabelWidget } from "common/form/widget/label-widget";
import { ColumnDefinition } from "common/query/advanced-types";
import { QueryForEntity } from "common/query/types";
import { RecordActions } from "common/record/actions";
import { RecordInfo } from "common/record/header/record";
import { Context } from "common/types/context";
import { Record } from "common/types/records";
import { GoFn } from "common/types/url";
import { Image } from "common/widgets/image";
import { getTitleFieldForEntity } from "common/entities/entity-column/functions";
import { LinkRecord } from "common/widgets/link-record";
import { getLayout } from "x/records/edit/functions";

interface PropTypes {
  context: Context;
  query: QueryForEntity;
  title: string;
  hasImage: boolean;
  definitions: ColumnDefinition[];
  reload: () => any;
  goTo: GoFn;
  record: Record;
  withLinks?: boolean;
  widgetsMapper?: MapWidgetPropsFn;
}

export const Card = ({
  context,
  query,
  title,
  hasImage,
  definitions,
  reload,
  goTo,
  record,
  withLinks,
  widgetsMapper,
}: PropTypes) => {
  const entity = context.entities[query.entity];
  const { properties, actions } = record;
  const contextSite = context.site.isGroup
    ? context.createContextForSiteWithFallback(properties.site)
    : context;

  const { id, isDeleted } = properties;
  const layout = getLayout(context, entity, { record, ui: undefined });
  const { header } = layout;
  const recordHeader = {
    ...header,
    title: title || header?.title || getTitleFieldForEntity(entity),
  };

  const content = definitions
    .map((def, i) => {
      const val =
        properties[def.valueKey] || properties[toCamelCase(def.valueKey)];

      const isFieldInHeader = Object.values(recordHeader).includes(
        def.valueKey,
      );
      return !R.isNil(val) && !isFieldInHeader ? (
        <li key={i} className="col-xs-12 col-sm-6 x-card-entry">
          <span className="x-card-entry-label">
            {getLocalizedName(def.column) || def.valueKey}
          </span>
          <span className="x-card-entry-value">
            <LabelWidget
              context={contextSite}
              column={def.column}
              withLinks={withLinks}
              recordId={id}
              entityName={entity.name}
              value={val}
            />
          </span>
        </li>
      ) : undefined;
    })
    .filter((v) => !!v);

  const titleValue = properties[title];
  const column = getColumn(entity, title);
  const getUrl = widgetsMapper?.()?.[column?.name]?.getUrl;
  const withLink = id
    ? (x: JSX.Element): JSX.Element => (
        <LinkRecord
          entity={contextSite.entities[query.entity]}
          site={contextSite.site.name}
          id={id}
          getUrl={getUrl}
        >
          {x}
        </LinkRecord>
      )
    : R.identity;

  const hasActions = !!actions?.length;
  const hasDetails = !!titleValue || hasActions;

  return (
    <div
      className={`x-card ${isDeleted ? "x-card-deleted" : ""} qa-card-${id}`}
    >
      <div className={hasDetails ? "x-card-detail" : "x-card-detail-empty"}>
        {hasImage ? (
          <div>{withLink(<Image value={properties.image} />)}</div>
        ) : undefined}
        <RecordInfo
          context={context}
          entity={entity}
          layout={recordHeader}
          withLinks={withLinks}
          withTitleLink={true}
          value={properties}
          onChange={undefined}
          getUrl={getUrl}
        />
        {hasActions ? (
          <div className="x-card-actions">
            <RecordActions
              goTo={goTo}
              context={contextSite}
              reload={reload}
              entity={entity}
              records={[record]}
              query={undefined}
              onTheRight={false}
              includeSwitchForm={false}
              displayType="icon"
            />
          </div>
        ) : undefined}
      </div>
      <div className="x-card-description">
        <ul className="row unstyled">{content}</ul>
      </div>
    </div>
  );
};

Card.displayName = "Card";
