import { isType } from "common/index";
import {
  CalendarReason,
  DocumentAddReason,
  DocumentRemoveReason,
  FailedProcedureOnWorkOrderCloseReason,
  FlukeReason,
  ManualReason,
  PMUpdateReason,
  Reason,
  RejectReason,
  RunningReason,
  ShadowReason,
  TotalsEstimationReason,
  TotalsReason,
  UrgentRepairReason,
} from "common/types/reasons";

export const isRejected = isType<Reason, RejectReason>(["rejectionReason"]);

export const isManual = (r: Reason): r is ManualReason => r.origin === "Manual";

export const isCalendar = (r: Reason): r is CalendarReason =>
  r.origin === "Calendar";

export const isTotals = (r: Reason): r is TotalsReason =>
  r.origin === "TotalsMeterReading";

export const isTotalsEstimation = (r: Reason): r is TotalsEstimationReason =>
  r.origin === "TotalsMeterReadingEstimation";

export const isRunning = (r: Reason): r is RunningReason =>
  r.origin === "RunningMeterReading";

export const isShadow = (r: Reason): r is ShadowReason =>
  r.origin === "ShadowEstimation";

export const isFromFluke = (r: Reason): r is FlukeReason =>
  r.origin === "Generated by Fluke CM";

export const isCsvCreate = (r: Reason) => r.origin === "Created from Csv";

export const isCsvUpdate = (r: Reason) => r.origin === "Updated from Csv";

export const isMeter = (r: Reason) => r.origin === "Meter";

export const isFrequency = (r: Reason) => r.origin === "Frequency";

export const isDocumentAdd = (r: Reason): r is DocumentAddReason =>
  r.origin === "Document Added";

export const isDocumentRemove = (r: Reason): r is DocumentRemoveReason =>
  r.origin === "Document Removed";

export const isBulkUpdate = (r: Reason) => r.origin === "bulk update";

export const isBulkDelete = (r: Reason) => r.origin === "bulk delete";

export const isBulkRestore = (r: Reason) => r.origin === "bulk restore";

export const isPM = (r: Reason): r is PMUpdateReason => r.origin === "PM";

export const isMeterReadingException = (r: Reason) =>
  r.origin === "MeterReadingException";

export const isFailedProcedureOnWorkOrderClose = (
  r: Reason,
): r is FailedProcedureOnWorkOrderCloseReason =>
  r.origin === "FailedProcedureOnWorkOrderClose";

export const isUrgentRepair = (r: Reason): r is UrgentRepairReason =>
  r.origin === "UrgentRepair";

export const isRimeRanking = (r: Reason) => r.origin === "RimeRanking";

export const isLAPComponentReassigned = (r: Reason) =>
  r.origin === "LAPComponentReassigned";

export const isScheduledWorkOrderUpdated = (r: Reason) =>
  r.origin === "ScheduledWorkOrderUpdated";
