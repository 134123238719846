import { useState, useMemo } from "react";
import { Context } from "common/types/context";
import { apiSearchFull } from "common/api/search";
import { pageSizes } from "common/types/pagination";
import { QueryForEntity, RunQuery } from "common/query/types";

export const usePagination = (
  context: Context,
  useFormattedQuery?: boolean,
) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [totalRecords, setTotalRecords] = useState(undefined);
  const [loadingRecords, setLoadingRecords] = useState(true);

  const handlePage = (page: number) => {
    setLoadingRecords(true);
    setPage(page);
  };

  const handlePageSize = (size: number) => {
    setLoadingRecords(true);
    setPage(0);
    setPageSize(size);
  };

  const runQuery = useMemo<RunQuery>(
    () => (query: QueryForEntity) => {
      const apiMethod = useFormattedQuery
        ? apiSearchFull(context.apiCallFull).runQueryWithPagination
        : apiSearchFull(context.apiCallFull).runRawQueryWithPagination;

      return apiMethod(query, context, page, pageSize).then(
        ({ data: { data = [], fullCount } }) => {
          setLoadingRecords(false);
          setTotalRecords(fullCount);

          return data;
        },
      );
    },
    [page, pageSize, context.apiCallFull],
  );

  return {
    page,
    pageSize,
    totalRecords,
    loadingRecords,

    onChangePage: handlePage,
    onChangePageSize: handlePageSize,
    runQuery,
  };
};
