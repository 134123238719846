import * as R from "ramda";
import { getColumn } from "common/entities";
import { getPathMap } from "common/query/joins";
import { Context } from "common/types/context";
import {
  getTitleFieldForEntity,
  isNumericColumn,
} from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import { Properties, Record } from "common/types/records";
import { Entities } from "common/entities/types";
import { TableConfig } from "common/query/table/types";
import { OrderField, QueryForEntity, SelectField } from "common/query/types";
import { Preferences, PreferenceService } from "common/types/preferences";
import { PropTypes } from "./types";

const pageSize = 10;

export const getQuery = ({
  recordId,
  query,
  entity,
  withOwnerId,
  orderBy,
}: PropTypes): QueryForEntity => {
  // take query:
  // 1 - remove the ownerId and createdOn from the select
  // 2 - add a filter stating the ownerId == recordId

  const ownerId = entity.ownerFkColumn;

  const isValidColumn = withOwnerId
    ? () => true
    : (s: SelectField) => (s.path && s.path !== "/") || s.name !== ownerId;

  return {
    entity: entity.name,
    query: R.mergeRight(query, {
      pageSize,
      joins: query.joins,
      select: R.prepend({ name: "id" }, query.select.filter(isValidColumn)),
      filter: {
        and: [
          { name: "isDeleted", op: "isfalse" },
          { name: ownerId, op: "eq", value: recordId },
        ],
      },
      order: orderBy ? [orderBy] : [],
    }),
  };
};

export const getConfig = (
  { ignore = [], actions, actionsWithContent }: PropTypes,
  query: QueryForEntity,
  entities: Entities,
): TableConfig => {
  const entityIgnore =
    entities[query.entity].type !== "SubEntity" ? [] : ["number"];

  return {
    ignore: [...ignore, "id", ...entityIgnore],
    actions,
    actionsWithContent,
    allowDrag: false,
    allowOrder: true,
  };
};

export const setWidthsToPreferences = (
  parentEntityName: string,
  site: string,
  entityName: string,
  widths: number[],
  preferenceService: PreferenceService,
) => {
  const currentPrefs = preferenceService.get();

  const relatedPrefs = R.find(
    (setting) =>
      setting.entityName === parentEntityName && setting.site === site,
    currentPrefs.related,
  );

  const newPrefs: Preferences = {
    ...currentPrefs,
    // removes current entity+site pref if exists and appends new merged
    related: R.without([relatedPrefs], currentPrefs.related).concat([
      {
        entityName: parentEntityName,
        site,
        widths: R.mergeRight(relatedPrefs?.widths, {
          [entityName]: widths,
        }),
      },
    ]),
  };

  preferenceService.set(newPrefs);
};

export const updateRecordsWithProperties = (
  records: Record[],
  properties: Properties[],
) => {
  const newPropsById: { [id: string]: Properties } = properties.reduce(
    (acc, properties) => ({
      ...acc,
      [properties.id || properties.tempId]: properties,
    }),
    {},
  );

  return records.map((r) => ({
    ...r,
    properties:
      newPropsById[r.properties.id || r.properties.tempId] || r.properties,
  }));
};

export const getIgnoreColumns = (ignore: string[] = []) => [
  "isDeleted",
  "tempId",
  ...ignore,
];

const compareNumbers = (a: number, b: number) => a - b;

const compareStrings = (a: string, b: string) =>
  (a ?? "").toString().localeCompare((b ?? "").toString());

export const getSortingFnForColumn = (
  column: EntityColumn,
): ((a: number | string, b: number | string) => number) =>
  column
    ? isNumericColumn(column)
      ? compareNumbers
      : compareStrings
    : () => 0;

export const getSortingColumn = (
  context: Context,
  query: QueryForEntity,
  orderBy: OrderField,
) => {
  if (!orderBy) return undefined;

  const pathMap = getPathMap(context.entities, query);
  const sortEntity = pathMap[orderBy.path ?? ""];
  const sortColumn = getColumn(sortEntity, orderBy.name);

  if (!sortColumn?.relatedEntity) return sortColumn;

  const relatedEntity = context.entities[sortColumn.relatedEntity];
  const titleColumnName = getTitleFieldForEntity(relatedEntity);
  return getColumn(relatedEntity, titleColumnName);
};
