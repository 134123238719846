import { stripHtml } from "common/utils/html";

interface ErrorDetailsProps {
  status?: number;
  message: string | string[];
}

export const ErrorDetails = ({ status, message }: ErrorDetailsProps) => {
  const errorStatus = status;
  const errorMessage = Array.isArray(message)
    ? message.map(stripHtml)
    : stripHtml(message);

  return (
    <div className="x-error-details-content">
      {errorStatus ? (
        <div className="qa-error-code row" data-testid="error-code">
          <div className="col-md-2">
            <strong>{_("Error Code:")}</strong>
          </div>
          <div className="col-md-10">{errorStatus}</div>
        </div>
      ) : undefined}

      <div className="qa-error-message row" data-testid="error-message">
        <div className="col-md-2">
          <strong>{_("Error Message:")}</strong>
        </div>
        <div className="col-md-10">{errorMessage}</div>
      </div>
    </div>
  );
};
