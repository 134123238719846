import { isSharedMultipleSitesEntity } from "common/entities";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { Site, Sites } from "common/types/sites";

export const findSite = (siteName: string, allSites: Site[]) =>
  (allSites || []).find((s) => s.name === siteName);

export const getSitesDictionary = (sites: Site[] | Sites): Sites => {
  if (Array.isArray(sites)) {
    const sitesDictionary: Record<string, Site> = {};
    sites.forEach((site) => {
      sitesDictionary[site.name] = site;
    });
    return sitesDictionary;
  } else {
    return sites ?? {};
  }
};

export const getParentToChildrenMap = (
  sites: Site[],
): Record<string, Site[]> => {
  const parentToChildrenMap: Record<string, Site[]> = {};
  sites.forEach((site) => {
    if (site.parentName) {
      if (!parentToChildrenMap[site.parentName]) {
        parentToChildrenMap[site.parentName] = [];
      }
      parentToChildrenMap[site.parentName].push(site);
    }
  });
  return parentToChildrenMap;
};

export const getAllDescendantNames = (
  site: Site,
  sites: Site[] = [],
): string[] => {
  const parentToChildrenMap = getParentToChildrenMap(sites);
  const descendants = new Set<string>();

  const collectDescendants = (currentSiteName: string) => {
    const children = parentToChildrenMap[currentSiteName] || [];
    for (const child of children) {
      descendants.add(child.name);
      collectDescendants(child.name);
    }
  };

  collectDescendants(site?.name);
  return Array.from(descendants);
};

export const getSitesSubTree = (site: Site, sites: Site[] = []): Site[] => {
  if (!site) return [];

  const parentToChildrenMap = getParentToChildrenMap(sites);
  const newRoot: Site = { ...site, parentName: undefined };
  const subTree = [newRoot];

  const collectSubTree = (currentSiteName: string) => {
    const children = parentToChildrenMap[currentSiteName] || [];
    for (const child of children) {
      subTree.push(child);
      collectSubTree(child.name);
    }
  };

  collectSubTree(site?.name);
  return subTree;
};

export const getNonGroupSiteNames = (sites: Site[]) =>
  sites?.filter((s) => !s.isGroup).map((s) => s.name);

export const displaySite = (sites: Site[]) => (name: string) =>
  sites?.find((s) => s.name === name)?.label;

export const hasMoreThanOneSite = (sites: Site[]) =>
  sites?.filter((s) => !s.isGroup).length > 1;

/**
 * Check if site is a child of parentSite
 * @param parentSite
 * @param site
 */
const isChildSite = (parentSite: Site, site: Site) =>
  parentSite?.treeLeft < site?.treeLeft &&
  parentSite?.treeRight > site?.treeRight;

/**
 * Check if site is the same or a child of parentSite
 * @param parentSite
 * @param site
 */
const isSameOrChildSite = (parentSite: Site, site: Site) =>
  parentSite?.treeLeft <= site?.treeLeft &&
  parentSite?.treeRight >= site?.treeRight;

export const areSitesSubsetOfSites = (
  allSites: Site[],
  relatedRecordSites: string[],
  mainRecordSites: string[],
) => {
  const realMainRecordSites = (mainRecordSites || []).map((mainRecordSite) =>
    findSite(mainRecordSite, allSites),
  );
  const realRelatedRecordSites = (relatedRecordSites || []).map(
    (relatedRecordSite) => findSite(relatedRecordSite, allSites),
  );

  return realMainRecordSites.every((mainRecordSite) =>
    realRelatedRecordSites.some((relatedRecordSite) =>
      isSameOrChildSite(relatedRecordSite, mainRecordSite),
    ),
  );
};

const isSharedMultipleSiteRecordAccessible = (
  allSites: Site[],
  currentSite: Site,
  recordSites: string[],
) => {
  return (
    !recordSites?.length ||
    recordSites
      .map((recordSite) => findSite(recordSite, allSites))
      .some(
        (recordSite) =>
          isSameOrChildSite(currentSite, recordSite) ||
          isChildSite(recordSite, currentSite),
      )
  );
};

export const isRecordAccessible = (
  context: Context,
  entity: Entity,
  value: Properties,
) => {
  return (
    !isSharedMultipleSitesEntity(entity) ||
    isSharedMultipleSiteRecordAccessible(
      context.sites,
      context.site,
      value?.sites,
    )
  );
};
