import * as R from "ramda";
import { EntityColumn } from "common/entities/entity-column/types";
import { getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { getLayoutGroupColumns } from "common/form/functions/common";
import { GroupColumn } from "common/form/types";
import { Form } from "common/types/forms";
import { QueryForEntity } from "common/query/types";
import { Record } from "common/types/records";

export const getPartSupplierQuery = (
  entityName: string,
  partIds: string[],
): QueryForEntity => {
  return {
    entity: entityName,
    query: {
      select: [
        { name: "number" },
        { name: "id" },
        { name: "currency" },
        { name: "unitCost" },
        { name: "supplierId" },
        { name: "id", alias: "partId", path: "/partId" },
        { name: "name", alias: "title", path: "/supplierId" },
      ],
      joins: [{ column: "supplierId" }, { column: "partId" }],
      filter: {
        and: [
          { name: "isDeleted", op: "isfalse" },
          {
            name: "partId",
            op: "in",
            value: partIds.join(","),
            excludeFromFkExpansion: true,
          },
        ],
      },
    },
  };
};

export const getPartsRecordProperties = (records: Record[]) => {
  return records.map((record) => record.properties);
};

const getKey = (obj: { columnName: string } | { name: string }) =>
  "columnName" in obj ? obj.columnName : obj.name;

const getUniqueColumns = (columns: EntityColumn[]) =>
  Array.from(new Map(columns.map((item) => [getKey(item), item])).values());

const getFormLayoutColumns = (form: Form) =>
  getLayoutGroupColumns(form?.settings.groups);

const getRequiredFieldsFromEntity = (entity: Entity): EntityColumn[] =>
  entity.columns.filter(
    (column) =>
      !column.readOnly &&
      column.required &&
      !column.unique &&
      !(column.relatedEntity && column.columnType === 1),
  );

const getRequiredFieldsFromForms = (
  entity: Entity,
  form: Form,
): EntityColumn[] => {
  const layoutColumns = getFormLayoutColumns(form);

  const meetsCondition = (lc: GroupColumn, entityColumn: EntityColumn) =>
    entityColumn?.readOnly &&
    !entityColumn?.unique &&
    (lc?.required || entityColumn?.required);

  return layoutColumns?.reduce((acc, lc) => {
    const entityColumn = getColumn(entity, lc.columnName);
    return meetsCondition(lc, entityColumn) ? [...acc, entityColumn] : acc;
  }, []);
};

const getNonMatchingColumns = (
  entityColumns: EntityColumn[],
  fakeEntityColumns: EntityColumn[],
): EntityColumn[] => {
  return R.differenceWith(
    (a: EntityColumn, b: EntityColumn) =>
      a.name === b.name && a.dataType === b.dataType,
    entityColumns,
    fakeEntityColumns,
  );
};

export const findRequiredColumns = (
  entity: Entity,
  selectedPOForm: Form,
  PartReorderListEntityColumns: EntityColumn[],
  supplierEntityColumns: EntityColumn[],
) => {
  const requiredFieldsFromEntity = getRequiredFieldsFromEntity(entity);
  const requiredFieldsFromForms = getRequiredFieldsFromForms(
    entity,
    selectedPOForm,
  );
  const entityColumns = getUniqueColumns([
    ...requiredFieldsFromEntity,
    ...requiredFieldsFromForms,
  ]);
  const missingFields = getNonMatchingColumns(entityColumns, [
    ...supplierEntityColumns,
    ...PartReorderListEntityColumns,
  ]);

  return missingFields;
};
