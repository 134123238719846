import { isType, isTypeWithoutValues } from "common";
import { SubQueryOperatorName } from "common/entities/operators";
import { CancellablePromise } from "common/types/promises";
import { Properties } from "common/types/records";

export interface Field {
  name: string;
  path?: string;
}

export interface Expression {
  expression: string;
}

export interface SummaryField {
  entityName: string;
}

type FieldItem = Field | Expression | SummaryField;

export const isField = isType<FieldItem, Field>([], ["expression"]);

// Select
export interface SelectField extends Field {
  fn?: string;
  alias?: string;
  label?: string;
}
export interface SelectExpression extends Expression {
  alias: string;
  label?: string;
}

export type SelectItem = SelectField | SelectExpression | SummaryField;
export type Select = SelectItem[];

export const isSelectField = isType<SelectItem, SelectField>(
  ["name"],
  ["expression", "entityName"],
);

export const isSelectFieldWithoutValues = isTypeWithoutValues<
  SelectItem,
  SelectField
>(["name"], ["expression", "entityName"]);

export const isSelectFieldWithAliasWithoutValues = isTypeWithoutValues<
  SelectItem,
  SelectField
>(["name", "alias"], ["expression", "entityName"]);

export const isSelectFieldWithFnWithoutValues = isTypeWithoutValues<
  SelectItem,
  SelectField
>(["name", "fn"], ["expression", "entityName"]);

export const isSelectExpression = isType<SelectItem, SelectExpression>(
  ["expression", "alias"],
  ["name", "entityName"],
);

export const isSelectExpressionWithoutValues = isTypeWithoutValues<
  SelectItem,
  SelectExpression
>(["expression", "alias"], ["name", "entityName"]);

export const isSelectFieldWithoutPath = isType<SelectItem, SelectField>(
  ["name"],
  ["expression", "entityName", "path"],
);

export const isSummaryField = isType<SelectItem, SummaryField>(
  ["entityName"],
  ["name", "expression"],
);

export const isSummaryFieldWithoutValues = isTypeWithoutValues<
  SelectItem,
  SummaryField
>(["entityName"], ["name", "expression"]);

// Order
export interface OrderField extends Field {
  fn?: string;
  desc?: boolean;
  alias?: string;
}
interface OrderExpression extends Expression {
  desc?: boolean;
}
export type OrderItem = OrderField | OrderExpression;
export type Order = OrderItem[];

export const isOrderField = isType<OrderItem, OrderField>(
  ["name"],
  ["expression"],
);
export const isOrderExpression = isType<OrderItem, OrderExpression>(
  ["expression"],
  ["name"],
);

// Group
export type GroupField = Field;
type GroupExpression = Expression;
export type GroupItem = GroupField | GroupExpression;
type Group = GroupItem[];

export const isGroupField = isType<GroupItem, GroupField>(
  ["name"],
  ["expression"],
);
export const isGroupExpression = isType<GroupItem, GroupExpression>(
  ["expression"],
  ["name"],
);

// Joins
export type JoinType = "INNER" | "LEFT" | "RIGHT";
export interface JoinItem {
  column: string;
  entity?: string;
  joins?: Joins;
  type?: JoinType;
  additionalOriginJoinField?: string;
  additionalOriginJoinEquates?: string;
}
type Joins = JoinItem[];

// Filter
export interface QueryField extends Field {
  alias?: string;
}

interface FilterBase {
  op: string;
  value?: any;
  valueFromColumn?: QueryField;
}

export interface FilterField extends Field, FilterBase {
  fn?: string;
  excludeFromFkExpansion?: boolean;
}

export interface FilterExpression extends Expression, FilterBase {}

export type FilterRule = FilterField | FilterExpression;

/**
 * A query used from within a filter. e.g. exists or notExists
 */
export interface FilterSubQuery {
  entity: string;
  filter: GroupFilter;
  joins?: Joins;
}

export type SubQueryScope = "related" | "currentSite" | "anySite";

export interface FilterSubQueryRule {
  op: SubQueryOperatorName;
  queryValue: FilterSubQuery;
  scope?: SubQueryScope;
}

export interface FilterAnd {
  and: Filter[];
}

export interface FilterOr {
  or: Filter[];
}

export type ConditionFilter = FilterRule | FilterSubQueryRule;
export type GroupFilter = FilterAnd | FilterOr;
export type Filter = GroupFilter | ConditionFilter;

export const isAnd = isType<Filter, FilterAnd>(["and"]);
export const isOr = isType<Filter, FilterOr>(["or"]);
export const isRule = isType<Filter, FilterRule>(
  ["op"],
  ["and", "or", "queryValue"],
);
export const isFieldRule = isType<Filter, FilterField>(
  ["name", "op"],
  ["and", "or", "queryValue"],
);
export const isExpressionRule = isType<Filter, FilterExpression>(
  ["expression", "op"],
  ["and", "or", "queryValue"],
);
export const isSubQuery = isType<Filter, FilterSubQueryRule>(
  ["op", "queryValue"],
  ["name"],
);

type Having = Filter;

export interface QueryRelatedSummary {
  [entityName: string]: number;
}

export interface Query {
  select: Select;
  order?: Order;
  group?: Group;
  joins?: Joins;
  filter?: Filter;
  having?: Having;
  relatedSummary?: QueryRelatedSummary;
  pageSize?: number;
  page?: number;
  cache?: boolean;
  fkExpansion?: boolean;
  title?: string;
  restrict?: string[];
  includeColorCoding?: boolean;
}

export interface QueryForEntity {
  entity: string;
  query: Query;
}

export interface Secondaries {
  [entityName: string]: Query;
}

//  this can either return json or files, so.. unknown
export type RunQuery = (query: QueryForEntity) => CancellablePromise<unknown>;

export type ActionsByRecordId = { [recordId: string]: string[] };
export type RunActionsQuery = (
  entity: string,
  recordIds: string[],
) => CancellablePromise<ActionsByRecordId>;

export type RunBulkQueries = (
  queries: QueryForEntity[],
) => CancellablePromise<unknown[]>;

export interface QueryContext {
  [key: string]: any;
}

export type RunQueryWithContext = (
  query: QueryForEntity,
  queryContext: QueryContext,
) => CancellablePromise<unknown>;

export type Page = Properties[];

export interface PagedResult {
  pages: Page[];
  isLastPage: boolean;
}

export interface PaginationValue<T> {
  fullCount: number;
  next: number;
  prev: number;
  totalPages: number;
  data: T[];
}

export type FilterType = "and" | "or";
