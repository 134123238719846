import * as R from "ramda";
import debounce from "lodash-es/debounce";
import { createRef, JSX, RefObject, useEffect, useMemo, useState } from "react";
import {
  ListChildComponentProps,
  ListOnScrollProps,
  VariableSizeList,
} from "react-window";
import { defaultFor } from "common";
import { CancellablePromise } from "common/types/promises";
import { DEFAULT_DEBOUNCE_TIMEOUT } from "common/with-debounce";
import { InputWithSearch } from "common/widgets/input-with-search";
import { LoadingIcon } from "common/widgets/loading-icon";
import { NodeComponent } from "common/widgets/lazy-tree/node";
import { Node, NodeLinkProps } from "common/widgets/lazy-tree/types";
import { getNodesForTree } from "common/widgets/lazy-tree/functions";
import { Sizer, Sizes } from "common/widgets/sizer";
import { classNames } from "common/utils/jsx";

import "./index.scss";

const DEFAULT_LIST_HEIGHT = 500;
const DEFAULT_ITEM_SIZE = 25;

interface PropTypes {
  className?: string;
  itemSize?: number;
  initialScrollOffset?: number;
  initialExpandedNodes?: string[];
  initialSearchTerm?: string;
  withNodeIcons?: boolean;
  requestRecords: () => CancellablePromise<Node[]>;
  isDisabled?: (node: Node) => boolean;
  isSelected?: (node: Node) => boolean;
  onSelect: (node: Node, nodes?: Node[]) => any;
  onDisplay?: (props: NodeLinkProps) => JSX.Element;
  onExpand?: (nodeId: string, allExpandedIds: string[]) => void;
  onScroll?: (props: number) => void;
  onSearchChange?: (searchTerm: string) => void;
  sortFn?: (a: Node, b: Node) => number;
}

export const LazyTree = ({
  className,
  itemSize,
  initialExpandedNodes,
  initialScrollOffset,
  initialSearchTerm,
  withNodeIcons,
  requestRecords,
  sortFn,
  isSelected,
  isDisabled,
  onSelect,
  onDisplay,
  onExpand,
  onScroll,
  onSearchChange,
}: PropTypes) => {
  const listRef: RefObject<VariableSizeList> = createRef();
  const [nodes, setNodes] = useState<Node[]>([]);
  const [displayNodes, setDisplayNodes] = useState<Node[]>([]);
  const [expandedNodes, setExpandedNodes] = useState<string[]>(
    initialExpandedNodes ?? [],
  );
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [isLoading, setIsLoading] = useState(false);

  const onSearchChangeDebounced = useMemo(
    () =>
      onSearchChange
        ? debounce(onSearchChange, DEFAULT_DEBOUNCE_TIMEOUT)
        : undefined,
    [onSearchChange],
  );

  const onListScrollDebounced = useMemo(
    () =>
      debounce(
        (props: ListOnScrollProps) => onScroll?.(props.scrollOffset),
        DEFAULT_DEBOUNCE_TIMEOUT,
      ),
    [onScroll],
  );

  useEffect(() => {
    setIsLoading(true);

    requestRecords()
      .then((nodes) => {
        setNodes(nodes);
        setDisplayNodes(
          getNodesForTree(nodes, searchTerm, expandedNodes, sortFn),
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setDisplayNodes(getNodesForTree(nodes, searchTerm, expandedNodes, sortFn));
  }, [searchTerm, expandedNodes]);

  const isExpanded = (node: Node) => {
    return expandedNodes.includes(node.id);
  };

  const onNodeExpand = (node: Node) => {
    const isAlreadyExpanded = expandedNodes.includes(node.id);

    const newExpandedNodes = isAlreadyExpanded
      ? expandedNodes.filter((n) => n !== node.id)
      : expandedNodes.concat([node.id]);

    setExpandedNodes(newExpandedNodes);
    onExpand?.(node.id, newExpandedNodes);
  };

  const onNodeSelect = (node: Node) => {
    onSelect(node, nodes);
  };

  const listItem = (
    listProps: ListChildComponentProps = defaultFor<ListChildComponentProps>(),
  ) => {
    const { index, style } = listProps;

    return (
      <div style={style}>
        <NodeComponent
          node={displayNodes[index]}
          isSelected={isSelected}
          textToHighlight={searchTerm}
          withNodeIcon={withNodeIcons}
          isExpanded={isExpanded(displayNodes[index])}
          isDisabled={isDisabled}
          onClick={onNodeSelect}
          onDisplay={onDisplay}
          onExpand={onNodeExpand}
        />
      </div>
    );
  };

  const renderTree = ({ width, height }: Sizes) => {
    return (
      <VariableSizeList
        ref={listRef}
        className="x-virtual-list"
        height={height ?? DEFAULT_LIST_HEIGHT}
        width={width}
        itemCount={displayNodes.length}
        itemSize={R.always(itemSize ?? DEFAULT_ITEM_SIZE)}
        initialScrollOffset={initialScrollOffset}
        onScroll={onListScrollDebounced}
      >
        {listItem}
      </VariableSizeList>
    );
  };

  const onSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    onSearchChangeDebounced?.(searchTerm);
    listRef.current.scrollTo(0);
  };

  if (isLoading) return <LoadingIcon />;

  return (
    <div className={classNames(["x-lazy-tree", className])}>
      <InputWithSearch value={searchTerm} onChange={onSearchTermChange} />
      {displayNodes?.length ? <Sizer render={renderTree} /> : _("No results")}
    </div>
  );
};
