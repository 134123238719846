import * as R from "ramda";
import { updateEqual } from "common";
import { mergeChain } from "common/merge";
import {
  getDataTypeForAggregateFn,
  getOperators,
} from "common/entities/data-types";
import { isRelatedSiteDataColumn } from "common/entities/entity-column/functions";
import { isUnaryOperator } from "common/entities/operators";
import { ColumnDefinition } from "common/query/advanced-types";
import { getAdjustedDateTimeFilterValue } from "common/query/filter";
import {
  FilterAnd,
  FilterOr,
  FilterRule,
  Filter,
  isSelectExpression,
  QueryForEntity,
  SelectItem,
  isFieldRule,
} from "common/query/types";

const isHaving = (field: SelectItem) => !!(field as any).fn;
const getPart = (isHaving: boolean, query: QueryForEntity) =>
  ((isHaving ? query.query.having : query.query.filter) as FilterAnd) || {
    and: [],
  };

export const findCondition = (
  field: SelectItem,
  filter: FilterAnd,
): FilterRule =>
  filter && filter.and
    ? (R.find(
        (f: FilterRule) =>
          isFieldRule(f) &&
          f.path === (field as any).path &&
          f.name === (field as any).name,
        filter.and,
      ) as FilterRule)
    : undefined;

export const getCondition = (
  columnDefinition: ColumnDefinition,
  query: QueryForEntity,
) => {
  const { item } = columnDefinition;
  const having = isHaving(item);
  const part = getPart(having, query);
  return findCondition(item, part);
};

const mergeQueryFilter = (
  query: QueryForEntity,
  isHaving: boolean,
  part: Filter,
): QueryForEntity =>
  mergeChain(query)
    .prop("query")
    .set("page", 0)
    .set(isHaving ? "having" : "filter", part)
    .output();

const mergeFilter = (
  parentFilter: Filter,
  oldFilter: FilterRule,
  newFilter: FilterRule,
  addFilter: boolean,
): Filter => {
  if (!parentFilter) return { and: [newFilter] };
  if ((parentFilter as FilterOr).or) return { and: [parentFilter, newFilter] };
  let newAnd = (parentFilter as FilterAnd).and;
  if (addFilter) {
    newAnd = oldFilter
      ? updateEqual(oldFilter, newFilter, newAnd)
      : R.append(newFilter, newAnd);
  } else if (oldFilter) {
    newAnd = newAnd.filter((f) => f !== oldFilter);
  }

  return newAnd.length ? { and: newAnd } : undefined;
};

export const setFilterOperator = (
  columnDefinition: ColumnDefinition,
  query: QueryForEntity,
  op: string,
): QueryForEntity => {
  const item: any = columnDefinition.item;
  const having = isHaving(item);
  const part = getPart(having, query);

  if (!part.and) return undefined;

  const condition = findCondition(item, part);

  if (!condition && !op) return undefined; // remove non-existing condition?

  const adjustedValue = getAdjustedDateTimeFilterValue(condition, op);
  const value = isUnaryOperator(op) ? undefined : adjustedValue;

  const rowFilter: Filter = {
    ...condition,
    name: item.name,
    path: item.path,
    fn: item.fn,
    op,
    value,
  };

  const newAnd =
    condition && op
      ? updateEqual(condition, rowFilter, part.and)
      : op
        ? R.append(rowFilter, part.and) // condition null, have op, add it
        : part.and.filter((c) => c !== condition); // op null, remove condition

  return mergeQueryFilter(query, having, { and: newAnd });
};

export const setFilterValue = (
  columnDefinition: ColumnDefinition,
  query: QueryForEntity,
  value: any,
): QueryForEntity => {
  const { column, item } = columnDefinition;
  const addFilter = value !== null && value !== undefined;

  // we can set either the FILTER or HAVING depending on the target field
  const having = isHaving(item);
  const part = getPart(having, query);

  // we can only remove from an and
  if ((!addFilter && !part.and) || isSelectExpression(item)) return undefined;

  const dataType = getDataTypeForAggregateFn(item) ?? column.dataType;

  const operators = getOperators(dataType, column.required, item);
  const condition = findCondition(item, part);

  const hasOp = (name: string) => R.any((o) => o.name === name, operators);

  const op = condition?.op;

  const nonUnaryOperator = R.find((o) => !o.unary, operators);

  const operator =
    op && value && isUnaryOperator(op) && nonUnaryOperator
      ? nonUnaryOperator.name
      : op;

  const newOperator = operator
    ? operator
    : hasOp("dayeq")
      ? "dayeq"
      : hasOp("contains")
        ? "contains"
        : "eq";

  const filterOp = dataType === "bool" ? value : newOperator;

  const filterValue =
    dataType === "fk" && !isRelatedSiteDataColumn(column)
      ? value?.id
      : dataType === "bool"
        ? undefined
        : value;

  const basicFilter: Filter = {
    name: item.name,
    op: filterOp,
    value: filterValue,
    path: item.path,
    fn: item.fn,
  };

  const filterExcludeExpansion = R.includes(dataType, [
    "fk",
    "systemintfk",
    "systemstringfk",
  ])
    ? { ...basicFilter, excludeFromFkExpansion: true }
    : basicFilter;

  const newFilter = mergeFilter(
    part,
    condition,
    filterExcludeExpansion,
    addFilter,
  );
  return mergeQueryFilter(query, having, newFilter);
};
