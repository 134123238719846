import * as R from "ramda";
import { Properties } from "common/types/records";
import { ColumnTypes, EntityColumn } from "common/entities/entity-column/types";
import { Context } from "common/types/context";
import {
  PreviewPayload,
  PurchaseOrderItem,
  PurchaseOrderPayload,
} from "./purchase-order/types";

export const getFakeEntityColumns = (context: Context) => {
  // we can't guess the relatedEntity here (if there's more than one entity by behavior)
  // we use the entity name which comes from the expanded FKs
  const columns: EntityColumn[] = [
    {
      name: "partId",
      localizedName: _("Part"),
      dataType: "fk",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      isForeignKey: true,
      relatedEntity: undefined,
    },
    {
      name: "description",
      localizedName: _("Description"),
      dataType: "string",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "locationId",
      localizedName: _("Part Location"),
      dataType: "fk",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      isForeignKey: true,
      relatedEntity: undefined,
    },
    {
      name: "preferredSupplier",
      localizedName: _("Preferred Supplier"),
      dataType: "fk",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      isForeignKey: true,
      relatedEntity: undefined,
    },
    {
      name: "onHand",
      localizedName: _("On Hand"),
      dataType: "int",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "onOrderQuantity",
      localizedName: _("On Order Quantity"),
      dataType: "int",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "reorderPoint",
      localizedName: _("Reorder Point"),
      dataType: "int",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "reorderQuantity",
      localizedName: _("Reorder Quantity"),
      dataType: "int",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "unitCost",
      localizedName: _("Unit Cost"),
      dataType: "currency",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      getDependencies: (row: Properties): Properties => ({
        currencyId: row.currency,
      }),
    },
    {
      name: "conversionRate",
      localizedName: "Conversion Rate",
      dataType: "ufloat",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "totalCost",
      localizedName: _("Total Cost"),
      dataType: "currency",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
  ];

  const siteColumn: EntityColumn = {
    name: "site",
    dataType: "string",
    columnType: ColumnTypes.System,
    localizedName: _("Site"),
    isSystem: true,
    readOnly: true,
    required: true,
    maxLength: 0,
  };

  return context.site.isGroup ? [siteColumn, ...columns] : columns;
};

export const toPurchaseOrderGroups = (
  purchaseOrderItems: PurchaseOrderItem[],
) => R.groupBy((item) => item.purchaseOrderNumber, purchaseOrderItems);

export const toPurchaseOrderPayload = (
  purchaseOrderItems: PurchaseOrderItem[],
  formId: number,
  missingPOValues: Properties,
  missingPOItemsValues: Properties[],
): PurchaseOrderPayload[] => {
  const groups = toPurchaseOrderGroups(purchaseOrderItems);

  return Object.keys(groups).map((poId) => {
    const { supplierId } = groups[poId][0];
    return {
      purchaseOrderNumber: poId,
      supplierId: supplierId.id,
      formId,
      properties: missingPOValues,
      items: groups[poId].map((poItem) => {
        return {
          partId: poItem.partId?.id,
          partLocationId: poItem.locationId?.id,
          reorderQuantity: poItem.reorderQuantity,
          unitCost: poItem.unitCost,
          stockEntity: poItem.stockEntity,
          properties:
            missingPOItemsValues.find((obj) => obj[poItem.id])?.[poItem.id] ||
            {},
        };
      }),
    };
  });
};

export const toPreviewPayload = (
  purchaseOrderItems: PurchaseOrderItem[],
): PreviewPayload[] => {
  const groups = toPurchaseOrderGroups(purchaseOrderItems);
  return Object.keys(groups).flatMap((poId) =>
    groups[poId].map((poItem) => ({
      partId: poItem.partId.id,
      partLocationId: poItem.locationId.id,
      supplierId: poItem.supplierId.id,
      reorderQuantity: poItem.reorderQuantity,
      unitCost: poItem.unitCost,
    })),
  );
};
