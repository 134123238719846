import * as R from "ramda";
import {
  isSelectField,
  SelectExpression,
  SelectField,
  SelectItem,
} from "common/query/types";
import { DataType } from "common/entities/entity-column/data-type/types";
import { operators, Operator } from "./operators";

export interface AggregateType {
  label: string;
  name: string;
}

const numTypes = ["int", "uint", "float", "ufloat", "currency"];
const num = (): AggregateType[] => [
  { name: "SUM", label: _("SUM") },
  { name: "COUNT", label: _("COUNT") },
  { name: "MAX", label: _("MAX") },
  { name: "MIN", label: _("MIN") },
  { name: "AVG", label: _("AVG") },
];

const other = (): AggregateType[] => [{ name: "COUNT", label: _("COUNT") }];

export const getFunctions = (dataType: DataType): AggregateType[] =>
  R.includes(dataType, numTypes) ? num() : other();

/**
 * Aggregate functions that return a numeric value.
 * Some of the functions can produce string outputs, but we limit them to be applied only for numeric types.
 */
const numericAggregates = ["SUM", "COUNT", "MAX", "MIN", "AVG"];

export const isNumericAggregate = (fn: string): boolean =>
  numericAggregates.includes(fn);

export const getDataTypeForAggregateFn = (item: SelectItem): DataType => {
  return isSelectField(item) && isNumericAggregate(item?.fn)
    ? "int"
    : undefined;
};

/**
 * Get a list of operators matching the dataType.
 * @param dataType
 * @param required The `required` prop of a column. If column is required, operators that check for null are omitted.
 * @param item
 */
export const getOperators = (
  dataType: DataType | "all",
  required: boolean,
  item?: SelectField | SelectExpression,
): Operator[] => {
  const dataTypeForOperator = getDataTypeForAggregateFn(item) ?? dataType;

  const ops =
    dataTypeForOperator !== "all"
      ? dataTypeForOperator === "image"
        ? operators().filter((op) => op.type === "optional")
        : operators().filter(
            (op) =>
              (!op.include || R.includes(dataTypeForOperator, op.include)) &&
              (!op.exclude || !R.includes(dataTypeForOperator, op.exclude)),
          )
      : operators();

  const requiredOps = required
    ? ops.filter((op) => op.name !== "isnull" && op.name !== "isnotnull")
    : ops;

  return requiredOps.map(R.omit(["include", "exclude"]));
};
