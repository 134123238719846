import { ApiErrorResponse } from "common/types/error";
import { CancelButtonSmall } from "common/ui/buttons";
import { ErrorDetails } from "common/ui/error/details";
import { getErrorMessage } from "common/ui/error/functions";
import { AlertWarning } from "common/widgets/alert";

interface PropsType {
  error: ApiErrorResponse | Error;
  onReset: () => void;
}

export const ListRenderError = ({ onReset, error }: PropsType) => {
  const errorMessage = _(
    "There was a problem rendering the table based on the given query. Please check your filters and try again.",
  );

  const errorButtons = [
    <CancelButtonSmall
      key="reset-filter"
      title={_("Reset filter")}
      onClick={onReset}
    >
      {_("Reset filter")}
    </CancelButtonSmall>,
  ];

  return (
    <AlertWarning message={errorMessage} buttons={errorButtons}>
      <ErrorDetails message={getErrorMessage(error)} />
    </AlertWarning>
  );
};
