import { JSX } from "react";
import { getError } from "common/api/error";
import { ApiErrorResponse } from "common/types/error";
import { getErrorMessage } from "common/ui/error/functions";
import { ErrorDetails } from "common/ui/error/details";
import { AlertWarning } from "common/widgets/alert";

interface PropTypes {
  error: ApiErrorResponse;
  mainMessage?: string;
  className?: string;
  buttons?: JSX.Element[];
}

export const ApiError = ({
  error,
  mainMessage,
  className,
  buttons,
}: PropTypes) => {
  const errorMessage = mainMessage ?? (error && getError(error));
  const hasDetails = error?.status !== 0;
  return errorMessage ? (
    <AlertWarning
      message={errorMessage}
      className={className}
      buttons={buttons}
    >
      {hasDetails ? (
        <ErrorDetails status={error?.status} message={getErrorMessage(error)} />
      ) : undefined}
    </AlertWarning>
  ) : null;
};
ApiError.displayName = "ApiError";
