import * as R from "ramda";
import { Component } from "react";
import { searchApi } from "common/api/search";
import { Int } from "common/widgets/number";
import {
  getDataTypeForAggregateFn,
  getOperators,
} from "common/entities/data-types";
import { filterBlackList } from "common/entities/entity-column/data-type/types";
import { isDateTimeOperator, Operator } from "common/entities/operators";
// eslint-disable-next-line import/no-cycle
import { InputWidget } from "common/form/widget/input-widget";
import { isFullEntity } from "common/functions/entity";
import { ColumnDefinition } from "common/query/advanced-types";
import { QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { Site } from "common/types/sites";
import { SITE_DATA_VIEW } from "common/types/view";
import { CalendarSelector } from "common/widgets/calendar-selector";
import { Date } from "common/widgets/date/date";
import { DateTime } from "common/widgets/date/date-time";
import { Selector } from "common/widgets/selector";
import { StringInput } from "common/widgets/input-with-submit/string";
import { withBuffer } from "common/widgets/line-buffered-widget";
import { RecordSelector } from "common/widgets/record-selector/selector";
import { ValueProps } from "common/with-value-for";
import { getCondition } from "./functions";

interface PropTypes {
  columnDefinition: ColumnDefinition;
  context: Context;
  query: QueryForEntity;
  disabled: boolean;
  placeholder?: string;
}

type Props = PropTypes & ValueProps<any>;

interface NameLabel {
  name: string;
  label: string;
}

const displayOperator = (o: Operator) => o.label;
const displaySite = (s: Site) => s.label;

export class TableFilterWidget extends Component<Props> {
  static readonly displayName = "TableFilterWidget";

  onChange = (value: Operator | Site) => {
    this.props.onChange(value?.name || undefined);
  };

  render() {
    const {
      columnDefinition,
      context,
      query,
      disabled,
      placeholder,
      value,
      onChange,
    } = this.props;
    const { column, entity, item } = columnDefinition;
    const {
      dataType,
      relatedEntity: relatedEntityName,
      name,
      required,
      relatedSystem,
    } = column;
    const { entities, apiCall, site, sites } = context;

    const wb = withBuffer(value, onChange);

    if (getDataTypeForAggregateFn(item) === "int") {
      return <Int value={value} onChange={onChange} />;
    }

    if (R.includes(dataType, filterBlackList)) {
      return (
        <StringInput value={undefined} onChange={undefined} disabled={true} />
      );
    }

    const relatedEntity = relatedEntityName && entities[relatedEntityName];
    if (relatedEntityName !== SITE_DATA_VIEW && isFullEntity(relatedEntity)) {
      return (
        <RecordSelector
          context={context}
          entity={relatedEntity}
          runQuery={searchApi(apiCall).runQueryForLookup}
          type="dropdown"
          allowClear={true}
          className="qa-filter-record-selector"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      );
    }

    if (name === "site") {
      const sitesList = site.isGroup
        ? sites.filter((s: Site) => !s.isGroup)
        : [];

      return (
        <Selector<NameLabel>
          className="qa-filter-site"
          getOptionLabel={displaySite}
          options={sitesList}
          allowClear={true}
          placeholder={placeholder}
          value={sitesList.find((s) => s.name === value)}
          onChange={this.onChange}
        />
      );
    }

    if (dataType === "bool") {
      const condition = getCondition(columnDefinition, query);
      const operatorsList = getOperators(dataType, required);
      const currentValue = condition?.op
        ? operatorsList.find((o) => o.name === condition.op)
        : undefined;

      return (
        <Selector<NameLabel>
          className="qa-filter-boolean"
          getOptionLabel={displayOperator}
          options={operatorsList}
          allowClear={true}
          placeholder={placeholder}
          value={currentValue}
          onChange={this.onChange}
        />
      );
    }

    if (dataType === "date") {
      return (
        <Date uiFormat={context.uiFormat} onChange={onChange} value={value} />
      );
    }

    if (dataType === "datetime") {
      const condition = getCondition(columnDefinition, query);
      return isDateTimeOperator(condition?.op) ? (
        <DateTime
          uiFormat={context.uiFormat}
          onChange={onChange}
          value={value}
        />
      ) : (
        <Date uiFormat={context.uiFormat} onChange={onChange} value={value} />
      );
    }

    if (dataType === "text" || dataType === "document") {
      return wb(<StringInput value={value} onChange={onChange} />);
    }

    if (dataType === "systemintfk" && relatedSystem === "Calendars") {
      return (
        <CalendarSelector
          calendars={context.calendars}
          allowClear={true}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
      );
    }

    return (
      <InputWidget
        placeholder={placeholder}
        buffer={true}
        disabled={disabled}
        validate={false}
        allowClear={true}
        context={context}
        column={column}
        entityName={entity}
        hideSymbol={true}
        formValidation={undefined}
        onFormValidationChange={undefined}
        onChange={onChange}
        value={value}
      />
    );
  }
}
