import { useEffect, useState } from "react";
import { findColumn } from "common/entities";
import { searchApi } from "common/api/search";
import { getSortingFnForColumn } from "common/entities/entity-column/functions";
import { Context } from "common/types/context";
import { NodeTreePrefs } from "common/types/preferences";
import { ValueProps } from "common/with-value-for";
import { Entity } from "common/entities/types";
import { Query } from "common/query/types";
import { Node } from "common/widgets/lazy-tree/types";
import { LazyTree } from "common/widgets/lazy-tree";
import {
  getFkToTree,
  getInFilter,
  getRecordsQuery,
} from "x/records/list/functional-location/functions";

import "./index.scss";
import { FunctionalLocationData } from "x/records/list/functional-location/types";

interface PropTypes extends ValueProps<Query> {
  context: Context;
  entity: Entity;
}

const defaultTreePref: NodeTreePrefs = {
  name: undefined,
  selectedId: undefined,
  expandedIds: [],
  scrollOffset: 0,
  searchTerm: undefined,
};

export const FunctionalLocation = ({
  context,
  entity,
  value,
  onChange,
}: PropTypes) => {
  const nodeTreesPref = context.preferenceService.get().nodeTrees;
  const treePrefsKey = `${context.site.name}/${entity.name}`;
  const treePrefs = nodeTreesPref?.find(
    (tree) => tree.name === treePrefsKey,
  ) ?? { ...defaultTreePref, name: treePrefsKey };

  const [selectedNodeId, setSelectedNodeId] = useState<string>(
    treePrefs?.selectedId,
  );

  const savePreferences = (newTreePrefs: NodeTreePrefs) => {
    const isAlreadySaved = nodeTreesPref.some(
      (tree) => tree.name === newTreePrefs.name,
    );
    const newNodeTrees = isAlreadySaved
      ? nodeTreesPref.map((tree) =>
          tree.name === newTreePrefs.name ? newTreePrefs : tree,
        )
      : [...nodeTreesPref, newTreePrefs];

    context.preferenceService.setByKey("nodeTrees", newNodeTrees);
  };

  useEffect(() => {
    if (!value?.filter && selectedNodeId) {
      setSelectedNodeId(undefined);
      savePreferences({ ...treePrefs, selectedId: undefined });
    }
  }, [value?.filter, treePrefs.selectedId]);

  const getFunctionalLocationRelatedEntity = () => {
    const foreignKeyToTreeEntity = getFkToTree(entity, context);
    return context.entities[foreignKeyToTreeEntity.relatedEntity];
  };

  const requestRecords = () => {
    return searchApi(context.apiCall)
      .runQuery(getRecordsQuery(getFunctionalLocationRelatedEntity()))
      .then((data: FunctionalLocationData[]) => {
        const nodes: Node[] = data.map((record) => ({
          id: record.id,
          label: record.title.toString(),
          isGroup: !!record.childrenCount,
          parentId: record.parentId,
          order: record.order,
          data: record,
        }));
        return nodes;
      });
  };

  const onNodeSelected = (node: Node, nodes: Node[]) => {
    const newFilter =
      node.id === selectedNodeId
        ? undefined
        : getInFilter(entity, context, node, nodes);

    onChange({ ...value, filter: newFilter });

    const newSelectedNodeId = node.id === selectedNodeId ? undefined : node.id;

    setSelectedNodeId(newSelectedNodeId);
    savePreferences({ ...treePrefs, selectedId: newSelectedNodeId });
  };

  const onNodeExpanded = (_: string, allExpandedIds: string[]) => {
    savePreferences({ ...treePrefs, expandedIds: allExpandedIds });
  };

  const isNodeSelected = (node: Node) => {
    return node.id === selectedNodeId;
  };

  const onTreeScroll = (scrollOffset: number) => {
    if (scrollOffset !== treePrefs.scrollOffset) {
      savePreferences({ ...treePrefs, scrollOffset });
    }
  };

  const onTreeSearchChange = (searchTerm: string) => {
    savePreferences({ ...treePrefs, searchTerm, scrollOffset: 0 });
  };

  const getSortFn = () => {
    const functionalLocationEntity = getFunctionalLocationRelatedEntity();
    const orderColumnName = functionalLocationEntity.arguments.order;

    if (!orderColumnName) return undefined;

    const orderColumn = findColumn(
      functionalLocationEntity.columns,
      orderColumnName,
    );

    if (!orderColumn) return undefined;

    const sortingFn = getSortingFnForColumn(orderColumn);

    return (a: Node, b: Node) => {
      if (!a.order && b.order) return 1;
      if (a.order && !b.order) return -1;
      if (!a.order && !b.order) return a.label.localeCompare(b.label);

      return sortingFn(a.order, b.order);
    };
  };

  return (
    <LazyTree
      className="x-functional-location"
      withNodeIcons={true}
      initialSearchTerm={treePrefs?.searchTerm}
      initialExpandedNodes={treePrefs?.expandedIds}
      initialScrollOffset={treePrefs?.scrollOffset}
      requestRecords={requestRecords}
      isSelected={isNodeSelected}
      sortFn={getSortFn()}
      onSelect={onNodeSelected}
      onExpand={onNodeExpanded}
      onScroll={onTreeScroll}
      onSearchChange={onTreeSearchChange}
    />
  );
};
