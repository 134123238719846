import * as R from "ramda";
import { Component } from "react";
import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import { MapWidgetPropsFn } from "common/form/types";
import { mergeChain } from "common/merge";
import { TableConfig, TableValue } from "common/query/table/types";
import { ActionsByRecordId, Query } from "common/query/types";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { Filter, defaultFilter } from "common/types/filters";
import { DefaultOutputs, Output } from "common/types/preferences";
import { CancellablePromise } from "common/types/promises";
import { GoFn } from "common/types/url";
import { eventLabel, trackEvent } from "common/utils/mixpanel";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { LabelledOptionOrGroup } from "common/vendor-wrappers/react-select/types";
import { PaginationFooter } from "common/widgets/pagination/footer";
import { Selector } from "common/widgets/selector";
import { DeleteWarning } from "common/widgets/warning/delete-warning";
import { ValueProps } from "common/with-value-for";
import { Crumb } from "x/layout/ribbon/breadcrumb";
import { QueryError } from "x/records/list/query-error";
import { ListRenderError } from "x/records/list/render-error";
import {
  ContentType,
  MenuValue,
  SaveFilterState,
  defaultValue,
} from "x/records/list/types";
import { FilterHeader } from "./filter";
import { FilterMenuValue } from "./filter/filter-menu";
import {
  getFilterById,
  getFiltersPermissions,
  getRecordsWithActions,
} from "./functions";
import { getOutputFilter } from "./functions/output";
import { ListBody } from "./list-body";
import { ListRibbon } from "./ribbon";
import { SaveFilterModal } from "./save-filter-modal";

interface PropTypes extends ValueProps<ContentType> {
  context: Context;
  entity: Entity;
  defaultQuery: Query;
  starred: string[];
  withLinks: boolean;
  reportName?: string;
  widgetsMapper?: MapWidgetPropsFn;
  config?: TableConfig;
  crumbs: Crumb[];
  displayHeader?: boolean;
  newPath?: string;
  output?: Output;

  // Api call state
  loadingRecords: boolean;
  error: ApiErrorResponse;
  filters: Filter[];
  records: any[];
  actionsByRecordId: ActionsByRecordId;
  page: number;
  totalRecords: number;
  pageSize: number;
  hidePagination?: boolean;

  // Handlers
  onSaveFilter: (x: Filter, isNew: boolean) => CancellablePromise<any>;
  onReload: () => void;
  onRemoveFilter: () => CancellablePromise<any>;
  onToggleStar: (id: string, starred: boolean) => void;
  onChangePage: (page: number) => void;
  onChangePageSize: (size: number) => void;
  goTo: GoFn;
}

interface StateType {
  modalValue?: Filter;
  saveFilterState?: SaveFilterState;
  lastFilterId?: number;
  confirmDelete?: boolean;
  renderError?: any;
}

export class Content extends Component<PropTypes, StateType> {
  static getDerivedStateFromError(error: ApiErrorResponse | Error) {
    return { renderError: error };
  }

  static readonly displayName = "Content";

  constructor(props: PropTypes) {
    super(props);
    this.state = {
      confirmDelete: false,
      lastFilterId: props.value?.filter?.id,
    };
  }

  componentDidUpdate(prevProps: Readonly<PropTypes>) {
    const oldFilter = prevProps.value.filter;
    const newFilter = this.props.value.filter;

    if (!R.equals(oldFilter, newFilter)) {
      this.setState({ renderError: undefined });
    }
  }

  showSaveViewModal = (saveFilterState: SaveFilterState) => {
    return () => {
      const { context, value } = this.props;
      const { filter } = value;
      const { canCreateShared } = getFiltersPermissions(context, [
        "CreateShared",
      ]);

      const modalValue =
        saveFilterState === "new"
          ? {
              ...defaultFilter,
              shared: canCreateShared ? filter.shared : false,
              entity: filter.entity,
              query: filter.query,
              secondaryQueries: filter.secondaryQueries,
            }
          : filter;

      this.setState({ modalValue, saveFilterState });
    };
  };

  onCancelModal = () => {
    this.hideModal(this.state.lastFilterId);
  };

  hideModal = (lastFilterId: number) => {
    this.setState({
      modalValue: undefined,
      saveFilterState: undefined,
      confirmDelete: false,
      lastFilterId,
    });
  };

  onFilterSaved = (newFilterId: number) => {
    const { modalValue, saveFilterState } = this.state;
    const { entity } = this.props;
    this.hideModal(newFilterId);
    if (saveFilterState === "new") {
      trackEvent(eventLabel.saveNewListView, {
        entity: entity.name,
        isGlobal: modalValue.shared,
      });
    }
  };

  onSaveFilter = () => {
    const { modalValue, saveFilterState } = this.state;
    const { onSaveFilter, entity } = this.props;
    const newFilter = { ...modalValue, entity: entity.name };
    return onSaveFilter(newFilter, saveFilterState === "new").then(
      (id: number) => {
        this.onFilterSaved(id || newFilter.id);
      },
    );
  };

  onChangeFilter = (filter: Filter) => {
    this.setState({ modalValue: filter });
  };

  onDeleteFilter = () => {
    this.setState({ confirmDelete: true });
  };

  onCancelDeleteFilter = () => {
    this.setState({ confirmDelete: false });
  };

  onConfirmDelete = () => {
    this.props.onRemoveFilter().then(() => this.hideModal(undefined));
  };

  onResetFilter = (toLastFilter: boolean) => {
    return () => {
      const { lastFilterId } = this.state;
      const { filters, value, onChange } = this.props;

      if (toLastFilter) {
        const lastFilter = getFilterById(filters, lastFilterId);
        onChange({ ...value, filter: lastFilter });
      } else {
        onChange({ ...value, filter: undefined });
        this.setState({ lastFilterId: undefined });
      }

      this.setState({ renderError: undefined });
    };
  };

  onChangeBody = (newValue: TableValue) => {
    const { value = defaultValue, onChange } = this.props;
    const oldFilter = value.filter;

    const query = newValue?.query?.query;
    const secondaryQueries = newValue?.secondaryQueries;

    const newFilter =
      query && query !== this.getQuery() ? { ...oldFilter, query } : oldFilter;

    onChange({
      ...value,
      filter: { ...newFilter, secondaryQueries },
      body: { ...newValue, query: undefined },
    });
  };

  getQuery = () => {
    const { value = defaultValue, defaultQuery } = this.props;
    return (value.filter || defaultFilter).query || defaultQuery;
  };

  onChangeMenu = <
    UiKey extends keyof ContentType,
    TEvent extends MenuValue<ContentType[UiKey]>,
  >(
    uiKey: UiKey,
    newValue: TEvent,
  ) => {
    const { value = defaultValue, onChange } = this.props;
    const { ui, query } = newValue;

    const queryChanged = query !== value.filter?.query;
    const newFilter = queryChanged ? { ...value.filter, query } : value.filter;

    onChange({ ...value, [uiKey as string]: ui, filter: newFilter });
  };

  onSelectFilter = (filter: Filter) => {
    const { value, onChange } = this.props;
    onChange({ ...value, filter });
    this.setState({ lastFilterId: filter?.id });
    trackEvent(eventLabel.selectSavedFilter, {
      entity: this.props.entity.name,
    });
  };

  onChangeFilters = (v: FilterMenuValue) => {
    this.onChangeMenu("filtersUi", v);
  };

  availableOutputs = () => {
    const { context, entity } = this.props;

    return DefaultOutputs.filter(getOutputFilter(context, entity));
  };

  formatOutput = (option: LabelledOptionOrGroup<Output>) => {
    if (isGroupedOption(option)) return option.label;

    switch (option.value) {
      case "Table":
        return (
          <>
            <div className="icon">
              <i className="fa fa-table" />
            </div>
            <div className="item qa-display-table">
              <p>{_("Table")}</p>
            </div>
          </>
        );
      case "Cards":
        return (
          <>
            <div className="icon">
              <i className="fa fa-id-card" />
            </div>
            <div className="item qa-display-cards">
              <p>{_("Cards")}</p>
            </div>
          </>
        );
      case "Tree":
        return (
          <>
            <div className="icon">
              <i className="fa fa-sitemap" />
            </div>
            <div className="item qa-display-tree">
              <p>{_("Tree")}</p>
            </div>
          </>
        );
      case "FunctionalLocation":
        return (
          <>
            <div className="icon">
              <i className="fa fa-sitemap" />
            </div>
            <div className="item qa-display-functional-location">
              <p>{_("Functional Location")}</p>
            </div>
          </>
        );
    }
  };

  onChangeOutput = (output: Output) => {
    const { value, onChange } = this.props;
    onChange({ ...value, output });
  };

  render() {
    const {
      context,
      entity,
      filters,
      records,
      actionsByRecordId,
      withLinks,
      page,
      crumbs,
      totalRecords,
      onChangePage,
      onReload,
      loadingRecords,
      starred,
      goTo,
      onToggleStar,
      reportName,
      error,
      value = defaultValue,
      defaultQuery,
      pageSize,
      onChangePageSize,
      hidePagination,
      widgetsMapper,
      config,
      displayHeader = true,
      newPath,
      output,
    } = this.props;

    const {
      modalValue,
      saveFilterState,
      lastFilterId,
      confirmDelete,
      renderError,
    } = this.state;
    const {
      output: displayFormat,
      filtersUi,
      filter = defaultFor<Filter>(),
    } = value;
    const { secondaryQueries } = filter;

    const viewFormat = output || displayFormat;
    const lastFilter = getFilterById(filters, lastFilterId);

    // Save filter modal
    const modal = modalValue ? (
      <SaveFilterModal
        key="save-filter-modal"
        filters={filters}
        onSave={this.onSaveFilter}
        onCancel={this.onCancelModal}
        onDelete={this.onDeleteFilter}
        saveFilterState={saveFilterState}
        lastFilterName={lastFilter?.name}
        context={context}
        value={modalValue}
        onChange={this.onChangeFilter}
      />
    ) : undefined;

    const query = this.getQuery();

    const body = (
      <ListBody
        context={context}
        entity={entity}
        loading={loadingRecords}
        withLinks={withLinks}
        records={records}
        actionsByRecordId={actionsByRecordId}
        output={viewFormat || "Table"}
        isReport={!!reportName}
        starred={starred}
        toggleStar={onToggleStar}
        reload={onReload}
        goTo={goTo}
        value={mergeChain(value.body)
          .set("secondaryQueries", secondaryQueries)
          .prop("query")
          .set("entity", entity.name)
          .set("query", query)
          .output()}
        onChange={this.onChangeBody}
        widgetsMapper={widgetsMapper}
        config={config}
      />
    );

    const selectedRecords = getRecordsWithActions(
      value?.body?.selected,
      actionsByRecordId,
    );

    return (
      <div className="x-container-with-ribbon">
        <div className="x-content-with-ribbon">
          <ListRibbon
            context={context}
            entity={entity}
            onReload={onReload}
            query={{ entity: entity.name, query }}
            crumbs={crumbs}
            reportName={reportName}
            selected={selectedRecords || []}
            goTo={goTo}
            newPath={newPath}
          />
          <div className="x-records-list">
            {displayHeader ? (
              <div className="x-records-list-header">
                <Selector<Output>
                  className="x-cards-table-tree qa-select-cards-table-tree"
                  options={this.availableOutputs()}
                  formatOption={this.formatOutput}
                  value={viewFormat}
                  onChange={this.onChangeOutput}
                />
                {viewFormat !== "Tree" ? (
                  <FilterHeader
                    context={context}
                    entity={entity}
                    filters={filters}
                    secondaryQueries={secondaryQueries}
                    starred={starred}
                    error={error}
                    defaultQuery={defaultQuery}
                    isReport={!!reportName}
                    lastFilterId={lastFilterId}
                    value={{ ui: filtersUi, query }}
                    showSaveViewModal={this.showSaveViewModal}
                    onSelectView={this.onSelectFilter}
                    onResetView={this.onResetFilter}
                    onChange={this.onChangeFilters}
                  />
                ) : undefined}
              </div>
            ) : undefined}
            <div className="x-records-list-content">
              {error ? (
                <QueryError error={error} onReset={this.onResetFilter(true)} />
              ) : undefined}
              {renderError ? (
                <ListRenderError
                  error={renderError}
                  onReset={this.onResetFilter(false)}
                />
              ) : (
                body
              )}
              {!hidePagination && viewFormat !== "Tree" ? (
                <PaginationFooter
                  page={page}
                  onChangePage={onChangePage}
                  pageSize={pageSize}
                  onChangePageSize={onChangePageSize}
                  totalRecords={totalRecords}
                  loadingRecords={loadingRecords}
                />
              ) : undefined}
            </div>
          </div>
        </div>
        {modal}
        {confirmDelete ? (
          <DeleteWarning
            confirmationTitle={_("The view will be deleted")}
            confirmationLabel={_("Yes, delete this view")}
            onCancel={this.onCancelDeleteFilter}
            onDelete={this.onConfirmDelete}
          />
        ) : undefined}
      </div>
    );
  }
}
