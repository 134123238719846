import { returnAndNotify } from "common/api/with-notifications";
import { ApiCall } from "common/types/api";
import { DocumentsSettings } from "common/types/documents-settings";
import { CancellablePromise } from "common/types/promises";

export const documentsSettingsApi = (apiCall: ApiCall) => ({
  get: (): CancellablePromise<DocumentsSettings> =>
    apiCall("get", "/api/admin/documents-settings"),
  set: (settings: DocumentsSettings) =>
    apiCall("put", "/api/admin/documents-settings", settings).then(
      returnAndNotify("The settings were successfully updated"),
    ),
});
