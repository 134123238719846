import { ApiErrorResponse } from "common/types/error";
import { stripHtml } from "common/utils/html";

const getMessageFromError = (error: ApiErrorResponse | Error | string) => {
  if (typeof error === "string") {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error.data === "string") {
    return error.data;
  }

  return error.data?.message ?? error.data?.Message ?? error.data?.error;
};

export const getErrorMessage = (error: ApiErrorResponse | Error | string) => {
  const message = getMessageFromError(error);

  const strippedMessage = Array.isArray(message)
    ? message.map(stripHtml)
    : stripHtml(message);

  return strippedMessage;
};
