import { useEffect, useState } from "react";
import { documentsSettingsApi } from "common/api/settings/documents-settings";
import { Context } from "common/types/context";
import { VerticalField } from "common/ui/field";
import { Selector } from "common/widgets/selector";
import {
  DocumentsScope,
  documentViews,
  getOptionLabel,
} from "common/types/documents-settings";

interface PropTypes {
  context: Context;
  loadDocuments: (scope?: DocumentsScope) => void;
}

export const DocumentsViewSelector = ({
  context,
  loadDocuments,
}: PropTypes) => {
  const [value, setValue] = useState<DocumentsScope>("all");
  const reload = (scope: DocumentsScope) => {
    setValue(scope);
    loadDocuments(scope);
  };

  useEffect(() => {
    documentsSettingsApi(context.apiCall)
      .get()
      .then((c) => reload(c.view))
      .catch(() => reload("all"));
  }, []);

  return (
    <VerticalField
      className="x-select-view"
      label={_("Select View")}
      input={
        <Selector<DocumentsScope>
          getOptionLabel={getOptionLabel}
          options={documentViews}
          value={value}
          onChange={reload}
        />
      }
    />
  );
};
