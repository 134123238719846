import * as R from "ramda";
import { JSX } from "react";
import { ValueComponent, ValueProps } from "common/with-value-for";
import { Chicklet, ChickletSize } from "common/widgets/chicklets/chicklet";

import "./chicklets.scss";

type Value<T> = T[];

interface PropTypes<T> {
  display?: (
    item: T,
    index?: number,
    disabled?: boolean,
  ) => string | JSX.Element;
  size?: ChickletSize;
  className?: string;
  disabled?: boolean;
  getChickletClassName?: (item: T) => string;
  getChickletInvalidMessage?: (item: T) => string | JSX.Element;
  isHighlightedChicklet?: (item: T, value?: Value<T>) => boolean;
  isValidChicklet?: (item: T, value?: Value<T>) => boolean;
  canDeleteChicklet?: (item: T) => boolean;
  sortFn?: (itemA: T, itemB: T) => number;
  isClickable?: (item: T) => boolean;
  onClick?: (item: T) => void;
}

export type Props<T> = PropTypes<T> & ValueProps<Value<T>>;

export class Chicklets<T = string> extends ValueComponent<
  Value<T>,
  PropTypes<T>
> {
  static readonly displayName = "Chicklets";

  removeChicklet = (removeIndex: number) => {
    return () => {
      const { value } = this.props;
      this.setValue(
        value.filter((_: T, index: number) => index !== removeIndex),
      );
    };
  };

  render() {
    const {
      value,
      display,
      size,
      className = "",
      disabled,
      sortFn,
      getChickletClassName,
      getChickletInvalidMessage,
      isHighlightedChicklet = R.F,
      isValidChicklet = R.T,
      canDeleteChicklet = R.T,
      isClickable,
      onClick,
    } = this.props;

    const itemsToDisplay = sortFn ? value.sort(sortFn) : value;

    return (
      <div className={`x-chicklets qa-chicklets ${className}`}>
        {itemsToDisplay.map((item: T, index: number) => {
          const displayItem = display ? display(item, index, disabled) : item;
          const onItemClick = isClickable?.(item)
            ? () => onClick?.(item)
            : undefined;

          return (
            <Chicklet<T>
              key={index}
              content={displayItem}
              size={size}
              isHighlighted={isHighlightedChicklet(item, itemsToDisplay)}
              isValid={isValidChicklet(item, itemsToDisplay)}
              isDisabled={disabled ?? false}
              className={getChickletClassName?.(item)}
              invalidMessage={getChickletInvalidMessage?.(item)}
              canDelete={canDeleteChicklet(item)}
              onDelete={this.removeChicklet(index)}
              onClick={onItemClick}
            />
          );
        })}
      </div>
    );
  }
}
