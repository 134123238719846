import * as R from "ramda";
import { Component, Fragment } from "react";
import { searchApi } from "common/api/search";
import { findColumn, getEntityByBehavior } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
// eslint-disable-next-line import/no-cycle
import { GroupField } from "common/form/group/field";
import { LabelWidget } from "common/form/widget/label-widget";
import { roundWithPrecision } from "common/math";
import { Section } from "common/record/form/content/detail/view/section-group";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { Properties } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { Label } from "common/widgets/label";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ValueProps } from "common/with-value-for";
import { getFakeEntityColumns, toPurchaseOrderGroups } from "../functions";
import { getPartSupplierQuery } from "./functions";
// eslint-disable-next-line import/no-cycle
import { POItem } from "./item";
import { PartSupplier, PurchaseOrderItem } from "./types";

interface PropTypes extends ValueProps<PurchaseOrderItem[]> {
  context: Context;
  isPreviewScreen: boolean;
  onPurchaseOrderChange: (poValue: Properties) => void;
  requiredPurchaseOrderValues: Properties;
  requiredPurchaseOrderColumns: EntityColumn[];
  requiredPurchaseOrderItemColumns: EntityColumn[];
  requiredPurchaseOrderItemsValues: Properties[];
  onRequiredPurchaseOrderItemChange: (
    requiredPurchaseOrderItemsValues: Properties[],
  ) => void;
}

interface StateTypes {
  partSuppliers: {
    [partId: string]: PartSupplier[];
  };
  loading: boolean;
  error: ApiErrorResponse;
}

export class PurchaseOrders extends Component<PropTypes, StateTypes> {
  static readonly displayName = "PurchaseOrders";
  state: StateTypes = {
    partSuppliers: {},
    loading: false,
    error: undefined,
  };

  onPoItemChange = (newPoItem: PurchaseOrderItem) => {
    const { onChange, value } = this.props;
    const { partSuppliers } = this.state;
    const { partId, supplierId, locationId } = newPoItem;

    if (
      !partSuppliers[partId.id]?.some(
        (partSupplier) => partSupplier.supplierId.id === supplierId.id,
      )
    ) {
      this.fetchPartSuppliers();
    }

    onChange(
      value.map((poItem) =>
        poItem.locationId === locationId && poItem.partId === partId
          ? newPoItem
          : poItem,
      ),
    );
  };

  componentDidMount() {
    this.fetchPartSuppliers();
  }

  fetchPartSuppliers = () => {
    const { context, value } = this.props;
    const groups = R.groupBy(
      R.always(getEntityByBehavior("PartSupplier", context.entities).name),
      value,
    );

    this.setState({
      loading: true,
      error: undefined,
    });
    Object.keys(groups).forEach((partSupplierEntity) => {
      const partIds = groups[partSupplierEntity].map(
        (item: PurchaseOrderItem) => item.partId.id,
      );

      searchApi(context.apiCall)
        .runQueryFkExpansion(getPartSupplierQuery(partSupplierEntity, partIds))
        .then((updatedPartSuppliers: any[]) => {
          const { partSuppliers } = this.state;
          this.setState({
            loading: false,
            partSuppliers: {
              ...partSuppliers,
              ...R.groupBy((item) => item.partId.id, updatedPartSuppliers),
            },
          });
        })
        .catch((error) => this.setState({ error, loading: false }));
    });
  };

  getTotalCost = (poItems: PurchaseOrderItem[]) =>
    poItems.reduce(
      (acc, { totalCost }) => roundWithPrecision(acc + totalCost, 2),
      0,
    );

  onRequiredPurchaseOrderItemsChange = (value: {
    [poItemId: string]: Properties;
  }) => {
    const {
      onRequiredPurchaseOrderItemChange,
      requiredPurchaseOrderItemsValues,
    } = this.props;

    const id = Object.keys(value)[0];
    const newEntry = value[id];

    const updatedPOItemsValues = requiredPurchaseOrderItemsValues
      .filter((item) => !item[id])
      .concat({ [id]: newEntry });

    onRequiredPurchaseOrderItemChange(updatedPOItemsValues);
  };

  getRequiredPurchaseOrderFields = () => {
    const {
      context,
      isPreviewScreen,
      requiredPurchaseOrderValues,
      requiredPurchaseOrderColumns,
      onPurchaseOrderChange,
    } = this.props;

    return requiredPurchaseOrderColumns.map((requiredColumn) => {
      const columnName = requiredColumn.name;
      return (
        <div className={`x-edit-field qa-${columnName}`} key={columnName}>
          <GroupField
            key={columnName}
            context={context}
            withLinks={false}
            groups={[]}
            entity={{
              name: getEntityByBehavior("PurchaseOrder", context.entities).name,
              type: "Entity",
              columns: requiredPurchaseOrderColumns,
              recordScope: "SingleSite",
              commands: [],
              labels: {},
              behaviors: [],
            }}
            layoutColumn={{
              required: true,
              readOnly: false,
              disabled: isPreviewScreen,
              highlighted: false,
              columnName,
            }}
            formValidation={undefined}
            onFormValidationChange={undefined}
            value={requiredPurchaseOrderValues}
            onChange={onPurchaseOrderChange}
          />
        </div>
      );
    });
  };

  render() {
    const {
      context,
      value,
      isPreviewScreen,
      requiredPurchaseOrderColumns,
      requiredPurchaseOrderItemColumns,
      requiredPurchaseOrderItemsValues,
    } = this.props;
    const { partSuppliers, error, loading } = this.state;
    const fakeEntityColumns = getFakeEntityColumns(context);

    const poGroups = toPurchaseOrderGroups(value);

    return (
      <>
        {loading ? <LoadingIcon /> : undefined}
        {error ? <ApiError error={error} /> : undefined}
        {requiredPurchaseOrderColumns?.length
          ? this.getRequiredPurchaseOrderFields()
          : undefined}
        {Object.keys(poGroups).map((poNumber) => {
          const totalCost = this.getTotalCost(poGroups[poNumber]);
          return (
            <div
              key={poNumber}
              className="x-purchase-order x-record-detail-view x-margin-0-i"
            >
              <Section title={`${_("Purchase Order no")}: ${poNumber}`}>
                {poGroups[poNumber].map((item, index) => (
                  <Fragment key={`${item.partId.id}${item.locationId?.id}`}>
                    {index > 0 ? <hr /> : undefined}
                    <POItem
                      context={context}
                      columns={fakeEntityColumns}
                      isPreviewScreen={isPreviewScreen}
                      value={item}
                      onChange={this.onPoItemChange}
                      partSuppliers={partSuppliers[item.partId.id] ?? []}
                      requiredPurchaseOrderItemColumns={
                        requiredPurchaseOrderItemColumns
                      }
                      requiredPurchaseOrderItemValue={requiredPurchaseOrderItemsValues?.find(
                        (value) => value?.[item.id],
                      )}
                      onRequiredPurchaseOrderItemChange={
                        this.onRequiredPurchaseOrderItemsChange
                      }
                    />
                  </Fragment>
                ))}
                {isPreviewScreen ? (
                  <div className="qa-total-cost-container x-padding-top-5 x-flex-end-center">
                    <Label value={`${_("Total Cost")}:`} />
                    <div className="x-total-cost">
                      <LabelWidget
                        context={context}
                        column={findColumn(fakeEntityColumns, "totalCost")}
                        value={totalCost}
                        currencyId={context.currency.id}
                      />
                    </div>
                  </div>
                ) : undefined}
              </Section>
            </div>
          );
        })}
      </>
    );
  }
}
