import { classNames } from "common/utils/jsx";

type TriState = true | false | "mixed";

interface PropTypes {
  value: TriState;
  onChange: (state: TriState) => void;
  isDisabled?: boolean;
  className?: string;
  label?: string;
}

/**
 * Toggles the value of a tri-state checkbox.
 * If the value is `true`, it will be toggled to `false`.
 * If the value is `false`, it will be toggled to `true`.
 * If the value is `mixed`, it will be toggled to `true`.
 *
 * @param value
 */
const toggleValue = (value: TriState) => !value || value === "mixed";

export const TriStateCheckbox = ({
  isDisabled = false,
  className = "",
  label,
  value,
  onChange,
}: PropTypes) => {
  const handleToggle = isDisabled
    ? undefined
    : () => onChange(toggleValue(value));

  const checkboxIconClass = classNames([
    value === "mixed"
      ? "fa fa-minus-square-o"
      : value === true
        ? "fa fa-check-square-o"
        : "fa fa-square-o",
  ]);

  const checkboxClass = classNames([
    "x-tri-state-checkbox",
    className,
    isDisabled ? "x-disabled" : undefined,
  ]);

  return (
    <div
      data-testid="tri-state-checkbox"
      role="checkbox"
      aria-checked={value}
      aria-label={label}
      onClick={handleToggle}
      className={checkboxClass}
    >
      <i className={checkboxIconClass} />
    </div>
  );
};
