import { Fragment } from "react";
import { Record } from "common/types/records";
import { Entity } from "common/entities/types";
import { LinkRecord } from "./link-record";

interface PropTypes {
  records: Record[];
  entity: Entity;
  site: string;
}

export const LinkRecordsWithSeparator = ({
  records = [],
  entity,
  site,
}: PropTypes) => {
  if (!records.length) return null;
  return (
    <>
      {records.map((record, index) => {
        const id = record.properties.id;
        return (
          <Fragment key={id}>
            {index > 0 ? ", " : undefined}
            <LinkRecord entity={entity} site={site} id={id} target="_blank">
              {record.properties.number}
            </LinkRecord>
          </Fragment>
        );
      })}
    </>
  );
};
