import { Label } from "common/widgets/label";
import { MediaFile } from "common/record/form/content/media/file";
import { blobsPathPart, getFileDetails, isPendingUpload } from "./functions";

interface Props {
  value: string;
  textToHighlight?: string;
}

export const DocumentLabel = ({ value, textToHighlight }: Props) => {
  const invalidValue = !value || typeof value !== "string";

  if (invalidValue) return <Label value={value} />;

  const isValuePendingUpload = isPendingUpload(value);

  return value?.includes(blobsPathPart) || isValuePendingUpload ? (
    <MediaFile
      file={getFileDetails(value)}
      textToHighlight={textToHighlight}
      isClickable={!isValuePendingUpload}
    />
  ) : (
    <Label value={value} />
  );
};

DocumentLabel.displayName = "DocumentLabel";
