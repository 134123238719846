export type DocumentsScope = "all" | "owned" | "related";

export const documentViews: DocumentsScope[] = ["all", "owned", "related"];

export interface DocumentsSettings {
  view: DocumentsScope;
}

export const getOptionLabel = (option: DocumentsScope) => {
  switch (option) {
    case "owned":
      return _("Documents for this record");
    case "related":
      return _("Documents for related entities");
    case "all":
    default:
      return _("All Documents");
  }
};
